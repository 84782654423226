<template>
    <v-container class="map-container" fluid fill-height>
        <div class="floating-ad">
            <AdBTWR dark v-if="!user.subscribed"/>
        </div>

        <div id="map"></div>

        <v-btn dark color="almostblack" id="map-options-btn" @click="dialogs.options.state = true">
            <v-icon>mdi-cog</v-icon>
        </v-btn>

        <MapPopupContent
            :properties="popupProperties"
            ref="popup-content"
            v-if="showPopup"
        />

        <v-dialog
            v-model="dialogs.options.state"
            :fullscreen="$vuetify.breakpoint.smAndDown"
            content-class="repeater-dialog"
            attach=".map-container"
            width="400"
        >
            <v-card>
                <v-toolbar color="rfaccent2" dense dark>
                    <v-toolbar-title>Map Options</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-icon size="18" @click="dialogs.options.state = false">mdi-close</v-icon>
                </v-toolbar>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <v-select
                                    v-model="mapStyle"
                                    label="Map Style"
                                    :items="mapStyles"
                                    outlined
                                    dense
                                    hide-details
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="px-4 py-0">
                                <v-switch
                                    v-model="options.showOffline"
                                    label="Show Offline Repeaters"
                                    color="primary"
                                    hide-details
                                    inset
                                    dense
                                    xclass="mb-2"
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" class="px-4 py-0">
                                <v-switch
                                    v-model="options.showOutdated"
                                    label="Show Stale Repeaters"
                                    color="primary"
                                    hide-details
                                    inset
                                    dense
                                    xclass="mb-2"
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-overlay
            absolute
            color="black"
            :value="dialogs.loading.state"
            class="text-center"
        >
            <LoadingAnimation/>
        </v-overlay>
    </v-container>
</template>

<style lang="scss">
    @import '~vuetify/src/styles/styles.sass';

    #map {
        width: 100%;
        height: 100%;
    }

    .map-container {
        padding: 0;
    }

    #map-options-btn {
        position: absolute;
        top: 20px;
        left: 10px;
        z-index: 5;
    }

    #map-options {
        position: absolute;
        top: 90px;
        left: 10px;
        width: 250px;
        z-index: 6;
    }

    .width-100 {
        width: 100%;
    }

    .mw-100 {
        max-width: 100%;
    }

    .repeater-dialog {
        margin-top: 64px;
        z-index: 1002;
    }
</style>

<script>
    import AdBTWR from '@/components/ads/BTWR.vue';
    import MapPopupContent from '@/components/MapPopupContent.vue';
    import LoadingAnimation from '@/components/LoadingAnimation.vue';
    import mapboxgl from 'mapbox-gl';
    import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
    import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
    import axios from 'axios';
    import config from '../config';
    import { useTitle } from '@vueuse/core';

    let map;

    export default {
        name: "MapboxGL",

        props: ['user'],

        components: {AdBTWR, MapPopupContent, LoadingAnimation},

        data: () => ({
            dialogs: {
                options: {
                    state: false
                },
                loading: {
                    state: false
                }
            },
            options: {
                showOutdated: false,
                showOffline: false
            },
            popupProperties: {},
            geoJsonSource: {
                type: 'geojson',
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 50,
                data: {}
            },
            geoJsonSourceCoverage: {
                type: 'geojson',
                cluster: false,
                clusterMaxZoom: 14,
                clusterRadius: 50,
                data: {}
            },
            showPopup: false,
            mapStyle: 'satellite-streets-v10',
            mapStyles: [
                {value: 'streets-v11', text: 'Streets (Color)'},
                {value: 'light-v10', text: 'Streets (Light)'},
                {value: 'dark-v10', text: 'Streets (Dark)'},
                {value: 'outdoors-v11', text: 'Outdoors'},
                {value: 'satellite-v9', text: 'Satellite'},
                {value: 'satellite-streets-v10', text: 'Satellite Streets'},
            ]
        }),

        watch: {
            // "$vuetify.theme.dark"() {
            //     this.initMap();
            //
            //     this.fetchRepeaters();
            // },

            options: {
                handler() {
                    this.fetchRepeaters();
                },
                deep: true
            },

            mapStyle() {
                this.switchStyle(this.mapStyle);
            },

            geoJsonSource: {
                handler() {
                    //if a repeater ID is passed in the route params, flyTo that repeater
                    if (this.$route.params.id) {
                        let found = this.$_.find(this.geoJsonSource.data.features, {properties: {id: parseInt(this.$route.params.id)}});
                        if (found) {
                            map.flyTo({
                                center: found.geometry.coordinates,
                                zoom: 11
                            });

                            this.openRepeaterPopup(map, found.geometry.coordinates, found.properties);
                        }
                    }
                },
                deep: true
            }
        },

        methods: {
            initMap() {
                mapboxgl.accessToken = config.MAPBOX_ACCESS_TOKEN;
                map = new mapboxgl.Map({
                    container: 'map',
                    //style: this.$vuetify.theme.dark ? 'mapbox://styles/mapbox/dark-v10' : 'mapbox://styles/mapbox/streets-v11',
                    style: 'mapbox://styles/mapbox/' + this.mapStyle,
                    center: [-100, 37],
                    zoom: 4
                });

                //use fitBounds to capture lower 48 at any screen size
                map.fitBounds([
                    [-130, 50],
                    [-65, 24]
                ]);

                if (this.$vuetify.breakpoint.smAndUp) {
                    map.addControl(
                        new MapboxGeocoder({
                            accessToken: mapboxgl.accessToken,
                            mapboxgl: mapboxgl
                        }), 'bottom-left'
                    );
                }

                map.addControl(new mapboxgl.FullscreenControl());

                map.addControl(new mapboxgl.NavigationControl());

                map.addControl(
                    new mapboxgl.GeolocateControl({
                        positionOptions: {
                            enableHighAccuracy: true
                        },
                        trackUserLocation: true
                    })
                );

                map.on('click', 'repeaters', e => {
                    //keep track of existing repeater ID before we modify it
                    const currentId = this.$route.params.id;

                    if (this.$route.params.id !== e.features[0].properties.id.toString()) this.$router.push({
                        name: 'map-id',
                        params: {id: e.features[0].properties.id}
                    });

                    //deselect existing coverage polygon
                    if (currentId) {
                        map.setFeatureState(
                            {source: 'repeater-coverage', id: currentId},
                            {selected: false}
                        );
                    }

                    map.flyTo({
                        center: e.features[0].geometry.coordinates,
                        zoom: 11
                    });

                    this.openRepeaterPopup(map, e.features[0].geometry.coordinates, e.features[0].properties);
                });

                map.on('click', 'repeater-cluster', e => {
                    let features = map.queryRenderedFeatures(e.point, {
                        layers: ['repeater-cluster']
                    });
                    let clusterId = features[0].properties.cluster_id;
                    map.getSource('repeater-data').getClusterExpansionZoom(
                        clusterId,
                        function (err, zoom) {
                            if (err) return;

                            map.easeTo({
                                center: features[0].geometry.coordinates,
                                zoom: zoom
                            });
                        }
                    );
                });

                let hoveredStateId = null;
                map.on('mouseenter', 'repeaters', (e) => {
                    map.getCanvas().style.cursor = 'pointer';

                    if (e.features.length > 0) {
                        if (hoveredStateId) {
                            map.setFeatureState(
                                {source: 'repeater-coverage', id: hoveredStateId},
                                {hover: false}
                            );
                        }
                        hoveredStateId = e.features[0].id;
                        map.setFeatureState(
                            {source: 'repeater-coverage', id: hoveredStateId},
                            {hover: true}
                        );
                    }
                });

                map.on('mouseleave', 'repeaters', () => {
                    map.getCanvas().style.cursor = '';

                    if (hoveredStateId) {
                        map.setFeatureState(
                            {source: 'repeater-coverage', id: hoveredStateId},
                            {hover: false}
                        );
                    }
                    hoveredStateId = null;
                });

                map.on('mouseenter', 'repeater-cluster', () => {
                    map.getCanvas().style.cursor = 'pointer';
                });

                map.on('mouseleave', 'repeater-cluster', () => {
                    map.getCanvas().style.cursor = '';
                });

            },

            removeExistingSources() {
                if (map.getLayer('repeaters')) {
                    map.removeLayer('repeaters');
                }
                if (map.getLayer('repeater-cluster')) {
                    map.removeLayer('repeater-cluster');
                }
                if (map.getLayer('count-layer')) {
                    map.removeLayer('count-layer');
                }
                if (map.getLayer('repeater-coverage')) {
                    map.removeLayer('repeater-coverage');
                }


                if (map.getSource('repeater-data')) {
                    map.removeSource('repeater-data');
                }
                if (map.getSource('repeater-coverage')) {
                    map.removeSource('repeater-coverage');
                }

                if (map.hasImage('mygmrslogo')) {
                    map.removeImage('mygmrslogo');
                }
            },

            addSources() {
                this.removeExistingSources();

                let layers = map.getStyle().layers;
                // find the index of the first symbol layer in the map style
                let firstSymbolId;
                for (let i = 0; i < layers.length; i++) {
                    if (layers[i].type === 'symbol') {
                        firstSymbolId = layers[i].id;
                        break;
                    }
                }

                map.addSource('repeater-data', this.geoJsonSource);

                map.addSource('repeater-coverage', this.geoJsonSourceCoverage);

                map.loadImage('/images/favicon32.png', (err, image) => {
                    if (err) throw err;

                    map.addImage('mygmrslogo', image);

                    // map.addLayer({
                    //     id: "repeater-heatmap",
                    //     type: "heatmap",
                    //     source: "repeater-data",
                    //     maxzoom: 9,
                    // });

                    map.addLayer({
                        id: "repeater-cluster",
                        // References the GeoJSON source defined above
                        // and does not require a `source-layer`
                        source: "repeater-data",
                        type: "circle",
                        // minzoom: 9,
                        paint: {
                            "circle-color": [
                                "step",
                                ["get", "point_count"],
                                this.$vuetify.theme.themes.dark.rfaccent, //"#51bbd6",
                                50,
                                this.$vuetify.theme.themes.dark.rforange, //"#f1f075",
                                100,
                                this.$vuetify.theme.themes.dark.error, //"#f28cb1",
                            ],
                            "circle-radius": [
                                "step",
                                ["get", "point_count"],
                                20,
                                100,
                                30,
                                750,
                                40,
                            ],
                        },
                        filter: ["has", "point_count"],
                    });

                    map.addLayer({
                        id: "repeaters",
                        // References the GeoJSON source defined above
                        // and does not require a `source-layer`
                        source: "repeater-data",
                        // minzoom: 9,
                        type: "symbol",
                        paint: {
                            "text-color": "#fff", //this.$vuetify.theme.dark ? "#fff" : "#000",
                            "text-halo-color": "#222",
                            "text-halo-width": 8,
                            // "text-halo-blur": 0,
                        },
                        layout: {
                            "icon-image": "mygmrslogo",
                            "icon-size": 0.75,
                            "text-field": ["format",
                                ["get", "title"]
                            ],
                            "text-font": [
                                "literal",
                                ["Roboto Regular"]
                            ],
                            "text-size": 12,
                            "text-offset": [0, 1],
                            "text-anchor": "top",
                            "text-justify": "center",
                            "text-transform": "uppercase",
                            "text-ignore-placement": true,
                        },
                        filter: ["!", ["has", "point_count"]],
                    });

                    map.addLayer({
                        id: "count-layer",
                        source: "repeater-data",
                        type: "symbol",
                        paint: {
                            "text-color": "#ffffff"
                        },
                        layout: {
                            "text-field": "{point_count_abbreviated}",
                            "text-size": 16
                        },
                        filter: ["has", "point_count"]
                    });

                    map.addLayer({
                        id: "repeater-coverage",
                        source: "repeater-coverage",
                        type: "fill",
                        paint: {
                            "fill-color": this.$vuetify.theme.themes.dark.success,
                            "fill-opacity": [
                                "case",
                                ["boolean", ["feature-state", "hover"], false],
                                0.5,
                                ["boolean", ["feature-state", "selected"], false],
                                0.5,
                                0
                            ]
                        },
                    }, firstSymbolId);

                });
            },

            switchStyle(layerId) {
                map.setStyle('mapbox://styles/mapbox/' + layerId);

                this.fetchRepeaters();
            },

            fetchRepeaters() {
                this.dialogs.loading.state = true;

                let geoJson = {
                    type: "FeatureCollection",
                    features: []
                };

                let geoJsonCoverage = {
                    type: "FeatureCollection",
                    features: []
                };

                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(config.API_LOCATION + '/repeaters?limit=99999&outdated=' + this.options.showOutdated + '&offline=' + this.options.showOffline, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.repeaters = response.data.items.map(o => {
                            o.Frequency = o.Frequency.replace('46X', '462');
                            if (!o.Name || this.$_.trim(o.Name).length === 0) o.Name = `${o.Location} ${o.Frequency.replace('462.', '')}`;

                            return {
                                id: o.ID,
                                title: o.Name,
                                location: o.Location,
                                state: o.State,
                                type: o.Type,
                                owner: o.Owner,
                                frequency: o.Frequency,
                                toneOut: o['PL Out'],
                                toneIn: o['PL In'],
                                status: o.Status === 'Online',
                                haat: o.HAAT,
                                radius: o.Radius,
                                node: o.Node ? o.Node.toString() : null,
                                description: o.Rules,
                                modified: o.Modified,
                                position: [o.Longitude, o.Latitude]
                            };
                        });

                        //hide repeaters with invalid coordinates and offline
                        this.repeaters = this.repeaters.filter(o => {
                            //hide hubs
                            if (this.$_.isString(o.node) && (o.node.length === 3 || o.node.substring(3, 5) === '00' || o.node.substring(3, 5) === '98' || o.node.substring(3, 5) === '99')) return false;
                            if (!this.options.showOffline && !o.status) return false;
                            return parseInt(o.position[1]) !== 0 && parseInt(o.position[0]) !== 0;
                        });

                        this.$_.each(this.repeaters, repeater => {
                            geoJson.features.push({
                                id: repeater.id,
                                type: "Feature",
                                properties: repeater,
                                geometry: {
                                    type: "Point",
                                    coordinates: [
                                        repeater.position[0],
                                        repeater.position[1]
                                    ]
                                }
                            });

                            let ret = this.createGeoJSONCircle([
                                repeater.position[0],
                                repeater.position[1]
                            ], this.range(repeater.radius, repeater.haat), 32);

                            geoJsonCoverage.features.push({
                                id: repeater.id,
                                type: "Feature",
                                properties: {id: repeater.id},
                                geometry: {
                                    type: "Polygon",
                                    coordinates: [ret]
                                }
                            });
                        });
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        }
                    })
                    .then(() => {
                        this.dialogs.loading.state = false;

                        this.geoJsonSource.data = geoJson;
                        this.geoJsonSourceCoverage.data = geoJsonCoverage;

                        this.addSources();
                    });
            },

            openRepeaterPopup(mapInstance, coordinates, properties) {
                this.popupProperties = properties;

                this.showPopup = true;

                this.$nextTick(() => {
                    new mapboxgl.Popup()
                        .setLngLat(coordinates)
                        .setDOMContent(this.$refs['popup-content'].$el)
                        .on('open', () => {
                            map.setFeatureState(
                                {source: 'repeater-coverage', id: properties.id},
                                {selected: true}
                            );
                        })
                        .on('close', () => {
                            if (properties.id) {
                                map.setFeatureState(
                                    {source: 'repeater-coverage', id: properties.id},
                                    {selected: false}
                                );
                            }
                        })
                        .addTo(map);
                });
            },

            createGeoJSONCircle(center, radiusInKm, points) {
                if (!points) points = 64;

                let coords = {
                    latitude: center[1],
                    longitude: center[0]
                };

                let km = radiusInKm;

                let ret = [];
                let distanceX = km / (111.320 * Math.cos(coords.latitude * Math.PI / 180));
                let distanceY = km / 110.574;

                let theta, x, y;
                for (let i = 0; i < points; i++) {
                    theta = (i / points) * (2 * Math.PI);
                    x = distanceX * Math.cos(theta);
                    y = distanceY * Math.sin(theta);

                    ret.push([coords.longitude + x, coords.latitude + y]);
                }
                ret.push(ret[0]);

                return ret;
            },

            range(radius, haat) {
                if (!radius) {
                    const distRepeater = ((4.12 * Math.sqrt(haat / 3.28084)));
                    const distRadio = ((4.12 * Math.sqrt(5 / 3.28084)));
                    return Math.round((distRadio + distRepeater) * 10) / 10
                } else {
                    return radius;
                }
            },
        },

        mounted() {
            useTitle('Repeater Map - myGMRS.com');

            this.initMap();

            this.fetchRepeaters();
        }
    }
</script>
